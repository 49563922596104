@layer components {
  .button {
    @apply px-[1em] sm:px-[1.75em] pt-[0.9em] pb-[0.7em];
    @apply text-button uppercase tracking-widest whitespace-nowrap;
    @apply border border-red;
    @apply bg-transparent hover:bg-red text-red hover:text-white transition-colors duration-300 ease-in-out;
  }

  .button-inverted {
    @apply border border-white;
    @apply bg-transparent hover:bg-white text-white hover:text-red transition-colors duration-300 ease-in-out;
  }
}
