.tns-outer
  .hero &
    @apply absolute inset-0

.tns-outer,
.tns-inner,
.tns-slider
  @apply block h-full

// .tns-ovh
//   @apply overflow-visible


// .tns-controls button
//   @apply absolute z-10 top-1/2 -translate-y-1/2
//   @apply w-16 h-16 xl:w-24 xl:h-24
//   @apply overflow-hidden
//   text-indent: -1111rem


//   &[data-controls="prev"]
//     @apply left-0 md:left-4 xl:left-8
//     background: url("/assets/images/arrow-left.svg") no-repeat
//     background-position: 50% 50%
//     background-size: 50% 50%
//     @screen md
//       background-size: 100% 100%
//   &[data-controls="next"]
//     @apply right-0 md:right-4 xl:right-8
//     background: url("/assets/images/arrow-right.svg") no-repeat
//     background-position: 50% 50%
//     background-size: 50% 50%
//     @screen md
//       background-size: 100% 100%
