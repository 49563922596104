.navigation

  &--trigger
    @apply w-16 h-16 md:h-20 md:w-20 z-30
    z-index: 100
    border: none
    background: none
    padding: 0
    outline: none !important

    // @apply text-current

    &-deco-01,
    &-deco-02,
    &-deco-03,
    &-deco-04
      @apply content-['']
      @apply block w-5 h-[2px] md:h-[3px] md:w-8
      @apply absolute top-1/2 left-1/2 -translate-x-1/2
      @apply transition-all duration-200 origin-center
      @apply bg-red
      .navigation__inverted &,
      .navigation__active &
        @apply bg-neutral


    &-deco-01
      @apply rotate-0
      @apply -translate-y-1.5 md:-translate-y-2
    &-deco-02
      @apply rotate-0
      @apply translate-y-1.5 md:translate-y-2
    &-deco-03
      @apply rotate-0
    &-deco-04
      @apply rotate-0

    .navigation__active &
      &-deco-01
        @apply opacity-0 -translate-y-4
      &-deco-02
        @apply opacity-0 translate-y-4
      &-deco-03
        @apply rotate-45
      &-deco-04
        @apply -rotate-45


  &--header
    @apply translate-x-full
    .navigation__active &
      @apply translate-x-0

  &--header-item
    @apply opacity-100 translate-y-0
    // transition: opacity 0.5s, transform 0.5s
    transition: opacity 0.5s
    .fade-out &
      // @apply opacity-0 translate-y-6 md:translate-y-8
      @apply opacity-0



// CONTENT TRANSLATION


// HERO
.hero-img
  .navigation__active &
    @apply -translate-x-1/3
.hero-typo
  .navigation__active &
    @apply -translate-x-2/3
.hero-emblem
  .navigation__active &
    @apply -translate-x-[33vw]
// /HERO


// BREADCRUMB
.breadcrumb
  @apply translate-x-0 transition-transform duration-500
  .navigation__active &
    @apply -translate-x-1/4
// /BREADCRUMB


// EDITOR
.editor
  .navigation__active &
    @apply -translate-x-1/3
// /EDITOR


// GALLERY
.gallery-slider
  .navigation__active &
    @apply -translate-x-1/3
.gallery-text
  .navigation__active &
    @apply -translate-x-[10vw]
// /GALLERY


// TEXT
.text-headline
  @apply translate-x-0 transition-transform duration-500
  .navigation__active &
    @apply -translate-x-1/3
.text-link
  @apply translate-x-0 transition-transform duration-500
  .navigation__active &
    @apply -translate-x-1/3
// /TEXT


// INSTA
.insta-headline
  @apply translate-x-0 transition-transform duration-500
  .navigation__active &
    @apply -translate-x-1/3
.insta-images
  @apply translate-x-0 transition-transform duration-500
  .navigation__active &
    @apply -translate-x-1/4
// /INSTA
