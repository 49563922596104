.form
  input,
  textarea
    @apply outline-none font-thin
    // @apply transition-colors active:bg-neutral-ultralight hover:bg-neutral-ultralight focus:bg-neutral-ultralight

  .uniform__potty,
  .form--hp
    @apply absolute -left-[1111rem] my-0

  .form--alert
    @apply text-red uppercase tracking-widest
    @apply block pt-1 md:pt-2

  .form--error
    @apply p-4 md:p-8 text-white bg-red font-normal uppercase tracking-widest
    @apply block pt-1 md:pt-2

  .form--actions
    text-align: center


  .form--item
    @apply my-4 md:my-6
    &.form--winelist
      @apply mb-10 md:mb-20

  .form--text
    @apply w-full border-b border-red
    @apply uppercase tracking-widest text-red
    @apply py-2 md:py-3 px-0
    @apply placeholder:text-red

  .form--checkbox
    label
      @apply uppercase tracking-widest text-red font-thin
    input
      @apply accent-red

  .form--item-textarea
    label
      @apply uppercase tracking-widest text-red font-thin
      @apply block mb-2
  .form--textarea
    @apply border border-red w-full
    @apply p-2 md:p-4 text-red uppercase tracking-widest

  .form--submit
    @apply font-thin px-[4em]
