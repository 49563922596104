.editor
  @apply tracking-wide max-w-[100ch]
  @apply translate-x-0 transition-transform duration-500

  h1
    @apply text-headline_editor font-fino lowercase text-red mb-[0.5em]
  h2
    @apply text-headline_small_editor font-vito font-light text-red uppercase tracking-wider mb-[0.1em]
  h3
    @apply text-headline_small_editor font-vito font-light mb-[0.1em]
  p
    @apply text-copy_editor font-vito font-light

  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-[2em]


  a
    @apply underline hover:text-red transition-colors duration-500
    // @apply before:content-['→'] before:mr-[0.25em]


  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  // ol,
  // ul
  //   @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-0 pl-4 lg:pl-6 relative
      &:before
        content: '— '
        @apply absolute left-0 lg:translate-y-[0.3em]
      p
        @apply mb-0


.editor-teasertext
  h2
    @apply text-headline_teaser uppercase tracking-wider font-thin mb-[0.15em]
    @apply max-w-[26ch] mx-auto text-pretty
  p
    @apply max-w-[40ch] mx-auto
    @apply text-copy_teaser font-vito font-light
    @apply tracking-wide
    // @apply uppercase tracking-wider

.editor-footer
  @apply uppercase tracking-wider
  p
    @apply text-copy_footer font-vito font-light
  h2
    @apply text-headline_footer font-vito font-normal
