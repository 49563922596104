.lays,
.lays-navigation
  svg
    // @apply block w-auto h-full
    @apply absolute inset-0 left-1/2 -translate-x-1/2 h-full w-auto


.lays-navigation
  &[data-current]
    .lays-navigation-toplevel a,
    svg .link
      @apply text-neutral

  &[data-current="marienburg"]
    .lays-navigation-toplevel a[data-slug="marienburg"],
    svg .link[data-slug="marienburg"]
      @apply text-red
  &[data-current="schaeferlay"]
    .lays-navigation-toplevel a[data-slug="schaeferlay"],
    svg .link[data-slug="schaeferlay"]
      @apply text-red
  &[data-current="weisserberg"]
    .lays-navigation-toplevel a[data-slug="weisserberg"],
    svg .link[data-slug="weisserberg"]
      @apply text-red

