@font-face {
  font-family: "Fino";
  src: url("/assets/fonts/FinoSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Vito";
  src: url("/assets/fonts/Vito-CondensedLight.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Vito";
  src: url("/assets/fonts/Vito-CondensedMedium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
